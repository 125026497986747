<template>
  <div class="container pt-lg-5 pb-lg-4 mx-auto text-center">
    <div class="row justify-content-lg-center">
      <div class="pt-lg-5 pb-lg-4 col-lg-2">
        <img class="img-fluid" src="../assets/success1.png" />
      </div>
    </div>
    <div class="row justify-content-lg-center">
      <div>
        <h2>{{ $t('Congratuations') }}!</h2>
        <br />
        <p>
          {{ $t('Your order has been received! Your order id is') }}:
          <a
            class="font-weight-bolder"
            :href="'/order-review?orderId=' + $route.query.orderId"
          >{{$route.query.orderId}}</a>
          <br />
          {{ $t('Please check your email for order summary.') }}
          <br />
          {{ $t('The estimated delivery of your certificate will be 30 business days.') }}
        </p>
      </div>
    </div>
    <div class="row justify-content-lg-center">
      <div class="pt-lg-5 pb-lg-4 col-lg-6">
        <img class="img-fluid" src="../assets/success2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
}
</script>


<style scoped>
</style>
